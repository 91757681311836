import { Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import { defaultControlRadiusString, defaultPointsString } from 'core';
import { FormikLocationField } from '../formik-fields/location-field.js';
import { FormikLocationMapField } from '../formik-fields/location-map-field.js';
import { FormikNumberField } from '../formik-fields/number-field.js';
import { FormikRadioGroup } from '../formik-fields/radio-group.js';
import { FormikTextField } from '../formik-fields/text-field.js';
import { withViewState } from './edit-control-card-view-state.js';

export const EditControlCardView = withViewState(
  ({ eventType, formik, radiusOverride, overlayImage }) => (
    <Card>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container>
          <Grid item xs={8}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormikRadioGroup
                    id="type"
                    formik={formik}
                    label="Type"
                    defaultValue="control"
                    items={[
                      { id: 'control', display: 'Control' },
                      { id: 'start', display: 'Start' },
                      { id: 'finish', display: 'Finish' },
                    ]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField id="_id" formik={formik} label="Code" />
                </Grid>
                {eventType === 'rogaine/points' &&
                !['start', 'finish'].includes(formik.values.type) ? (
                  <Grid item xs={6}>
                    <FormikNumberField
                      id="points"
                      formik={formik}
                      label="Points"
                      type="number"
                      placeholder={defaultPointsString(
                        formik.values._id,
                        formik.values.type,
                      )}
                      helperText={
                        !formik.values.points
                          ? `Default of ${
                              defaultPointsString(
                                formik.values._id,
                                formik.values.type,
                              ) ?? 0
                            } will be used`
                          : undefined
                      }
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <FormikLocationField
                    id="location"
                    formik={formik}
                    label="Location"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikNumberField
                    id="radius"
                    formik={formik}
                    label="GPS Radius"
                    type="number"
                    placeholder={defaultControlRadiusString(
                      eventType,
                      radiusOverride,
                    )}
                    helperText={
                      !formik.values.radius
                        ? `Default of ${defaultControlRadiusString(
                            eventType,
                            radiusOverride,
                          )} will be used`
                        : undefined
                    }
                    unit="m"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikNumberField
                    id="altitude"
                    formik={formik}
                    label="Altitude"
                    type="number"
                    unit="m"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={formik.handleReset}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button color="primary" type="submit" variant="contained">
                Done
              </Button>
            </CardActions>
          </Grid>
          <Grid item xs={4}>
            <FormikLocationMapField
              id="location"
              formik={formik}
              height="100%"
              overlayImage={overlayImage}
            />
          </Grid>
        </Grid>
      </form>
    </Card>
  ),
);
