import type { EventControl } from 'core';
import type { FC } from 'react';
import { useState } from 'react';
import type { OverlayImage } from '../../utils/types.js';
import { ControlCardView } from './control-card-view.js';

interface Props {
  controls: EventControl[];
  editControl?: EventControl;
  eventType: string;
  onChangeControl: (from: EventControl, to: EventControl) => void;
  onRemoveControl: (control: EventControl) => void;
  onStartEdit: (control: EventControl | undefined) => void;
  overlayImage?: OverlayImage | null;
}

export const ControlCardList: FC<Props> = ({
  controls,
  editControl,
  eventType,
  onChangeControl,
  onRemoveControl,
  overlayImage,
  onStartEdit,
}) => {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const toggleExpanded = (controlId: string): void => {
    const expanded = expandedIds.includes(controlId);
    if (expanded) {
      setExpandedIds(expandedIds.filter((id) => id !== controlId));
    } else {
      setExpandedIds([...expandedIds.slice(-4), controlId]);
    }
  };

  return (
    <>
      {controls.map((control) => (
        <ControlCardView
          key={control._id}
          canEdit={!editControl}
          control={control}
          edit={editControl?._id === control._id}
          eventType={eventType}
          expanded={expandedIds.includes(control._id)}
          onCancelEdit={() => onStartEdit(undefined)}
          onChanged={(updated) => onChangeControl(control, updated)}
          onExpandedToggle={() => toggleExpanded(control._id)}
          onRemove={() => onRemoveControl(control)}
          onStartEdit={() => onStartEdit(control)}
          overlayImage={overlayImage}
        />
      ))}
    </>
  );
};
