import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import type { EventControl } from 'core';
import type { FC } from 'react';
import type { OverlayImage } from '../../utils/types.js';
import { DisplayControlCardView } from './display-control-card-view.js';
import { EditControlCardView } from './edit-control-card-view.js';

interface Props {
  canEdit: boolean;
  control: EventControl;
  edit: boolean;
  eventType: string;
  expanded: boolean;
  onCancelEdit: () => void;
  onChanged: (control: EventControl) => void;
  onExpandedToggle: () => void;
  onRemove: () => void;
  onStartEdit: () => void;
  overlayImage?: OverlayImage | null;
}

export const ControlCardView: FC<Props> = ({
  canEdit,
  control,
  edit,
  eventType,
  expanded,
  onCancelEdit,
  onChanged,
  onExpandedToggle,
  onRemove,
  onStartEdit,
  overlayImage,
}) => {
  if (edit || (canEdit && !control._id)) {
    return (
      <EditControlCardView
        control={control}
        eventType={eventType}
        onCancel={onCancelEdit}
        onDone={(updated) => {
          onChanged(updated);
        }}
        overlayImage={overlayImage}
      />
    );
  }

  return (
    <DisplayControlCardView
      control={control}
      eventType={eventType}
      expanded={expanded}
      menuItems={[
        {
          key: 'edit',
          hidden: !canEdit,
          icon: <EditIcon fontSize="small" />,
          label: 'Edit',
          onClick: () => onStartEdit(),
        },
        {
          key: 'remove',
          confirm: true,
          icon: <DeleteIcon fontSize="small" />,
          label: 'Remove',
          onClick: () => onRemove(),
        },
      ]}
      onExpandedToggle={onExpandedToggle}
      overlayImage={overlayImage}
    />
  );
};
