import type { TextFieldProps } from '@mui/material';
import { InputAdornment, TextField } from '@mui/material';
import type { FormikProps } from 'formik';

type Props<ID, T> = TextFieldProps & {
  id: ID;
  formik: FormikProps<T>;
  unit?: string;
};

export function FormikNumberField<
  ID extends string,
  T extends { [key in ID]?: number },
>(props: Props<ID, T>): JSX.Element {
  const { formik, unit, ...textProps } = props;
  return (
    <TextField
      autoComplete="off"
      fullWidth
      {...textProps}
      disabled={formik.isSubmitting || props.disabled}
      value={formik.values[props.id] ?? ''}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      name={props.name ?? props.id}
      error={
        (formik.touched[props.id] && Boolean(formik.errors[props.id])) ||
        props.error
      }
      helperText={formik.errors[props.id]?.toString() || props.helperText}
      InputProps={{
        endAdornment: unit ? (
          <InputAdornment position="end">{unit}</InputAdornment>
        ) : null,
      }}
    />
  );
}
