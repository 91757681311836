import { captureException } from '@sentry/react';
import type { EventControl, GeoJSONPoint } from 'core';
import type { FormikProps } from 'formik';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import type { Contract, ValueProcessor } from 'validata';
import { isObject, isString, maybeNumber, maybeString } from 'validata';
import type { OverlayImage } from '../../utils/types.js';
import { formikCheck } from '../../utils/validation.js';

const eventControlContract: Contract<EventControl> = {
  _id: isString({ minLength: 1 }),
  location: isObject<GeoJSONPoint>(),
  radius: maybeNumber({ min: 2, max: 100 }),
  altitude: maybeNumber({ min: 0, max: 8000 }),
  points: maybeNumber({ min: 0 }),
  type: maybeString({ regex: /^control|start|finish$/ }) as ValueProcessor<
    'control' | 'start' | 'finish'
  >,
};

interface ViewProps {
  eventType: string;
  formik: FormikProps<EventControl>;
  radiusOverride?: number | null | undefined;
  overlayImage?: OverlayImage | null;
}

interface Props {
  control: EventControl;
  eventType: string;
  onCancel?: () => void;
  onDone: (control: EventControl) => void;
  overlayImage?: OverlayImage | null;
}

export const withViewState =
  <P extends Props>(Component: React.ComponentType<ViewProps>): React.FC<P> =>
  ({
    control,
    eventType,
    onCancel: handleCancel,
    onDone: handleDone,
    overlayImage,
  }) => {
    const formik = useFormik<EventControl>({
      initialValues: control,
      onSubmit: (data) => {
        handleDone(data);
      },
      onReset: handleCancel,
      validate: async (value) => formikCheck(eventControlContract, value),
      validateOnBlur: true,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: using only formik.values, formik.validateForm avoids a validation loop
    useEffect(() => {
      formik.validateForm().catch(captureException);
    }, [formik.values, formik.validateForm]);

    return (
      <Component
        eventType={eventType}
        formik={formik}
        overlayImage={overlayImage}
      />
    );
  };
