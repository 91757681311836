import {
  ExpandLess as CollapseIcon,
  ExpandMore as ExpandIcon,
  MoreVert as MoreIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import type { EventControl } from 'core';
import { defaultControlRadius, defaultPoints, fromEventControl } from 'core';
import type { FC } from 'react';
import { formatMeters } from '../../utils/format.js';
import type { OverlayImage } from '../../utils/types.js';
import { InfoFieldStack, InfoFieldView } from '../info-field/info-field.js';
import { CenterMiniMap } from '../map/center-mini-map/controller.js';
import { ControlsLayer } from '../map/layers/course.js';
import { OverlayImageLayer } from '../map/layers/overlay-image.js';
import { MapStyles } from '../map/map-styles.js';
import { ReactComponent as StartIcon } from '../map/markers/isom-701.svg';
import { ReactComponent as ControlIcon } from '../map/markers/isom-703.svg';
import { ReactComponent as FinishIcon } from '../map/markers/isom-706.svg';
import type { ConfirmMenuItemProps } from '../menu-controller/menu-controller.js';
import { MenuController } from '../menu-controller/menu-controller.js';

interface Props {
  control: EventControl;
  eventType: string;
  expanded: boolean;
  menuItems?: ConfirmMenuItemProps[];
  onExpandedToggle: () => void;
  overlayImage?: OverlayImage | null;
}

export const DisplayControlCardView: FC<Props> = ({
  control,
  eventType,
  expanded,
  menuItems,
  onExpandedToggle,
  overlayImage,
}) => (
  <Card sx={{ width: '100%' }}>
    <Grid container>
      <Grid item xs={expanded ? 8 : 12}>
        <CardContent>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                {control.type === 'start' ? (
                  <>
                    <StartIcon height={24} width={24} />
                    <Typography variant="h5">Start ({control._id})</Typography>
                  </>
                ) : control.type === 'finish' ? (
                  <>
                    <FinishIcon height={24} width={24} />
                    <Typography variant="h5">Finish ({control._id})</Typography>
                  </>
                ) : (
                  <>
                    <ControlIcon height={24} width={24} />
                    <Typography variant="h5">Control {control._id}</Typography>

                    {eventType === 'rogaine/points' &&
                    !['start', 'finish'].includes(control.type) ? (
                      <Typography
                        variant="h5"
                        color="text.secondary"
                        fontStyle="italic"
                      >
                        {((pts: number | undefined) =>
                          pts ? `${pts} pts` : '-')(
                          control.points ??
                            defaultPoints(control._id, control.type),
                        )}
                      </Typography>
                    ) : null}
                  </>
                )}
              </Stack>
              <Stack direction="row" sx={{ my: '-8px !important' }}>
                {menuItems?.length ? (
                  <MenuController
                    button={(handleClick) => (
                      <IconButton onClick={handleClick}>
                        <MoreIcon />
                      </IconButton>
                    )}
                    horizontalAlign="right"
                    items={menuItems}
                  />
                ) : null}
                <IconButton onClick={() => onExpandedToggle()}>
                  {!expanded ? <ExpandIcon /> : <CollapseIcon />}
                </IconButton>
              </Stack>
            </Stack>
            <Collapse in={expanded}>
              <Box sx={{ mt: 1 }}>
                <InfoFieldStack>
                  <InfoFieldView label="GPS Radius">
                    {formatMeters(
                      control.radius ?? defaultControlRadius(eventType),
                    )}
                  </InfoFieldView>
                  <InfoFieldView label="Altitude">
                    {formatMeters(control.altitude) ?? '-'}
                  </InfoFieldView>
                </InfoFieldStack>
              </Box>
            </Collapse>
          </Stack>
        </CardContent>
      </Grid>
      {expanded ? (
        <Grid item xs={4}>
          <CenterMiniMap
            center={control.location}
            mapStyle={MapStyles.SATELLITE}
            initialZoom={eventType === 'sprint' ? 17 : 15.5}
            minHeight={150}
          >
            {overlayImage ? (
              <OverlayImageLayer
                corners={overlayImage.corners}
                imageUrl={overlayImage.url}
                interpolateOpacity={true}
              />
            ) : null}
            <ControlsLayer
              id="control"
              eventType={eventType}
              controls={[
                {
                  _id: control._id,
                  ...fromEventControl(control),
                },
              ]}
            />
          </CenterMiniMap>
        </Grid>
      ) : null}
    </Grid>
  </Card>
);
